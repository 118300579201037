import React from 'react'
import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { Grid } from '@mui/material'
import renderRichText from '@system/utils/richtext'

import { ZeitstrahlSlideProps as SlideProps } from '@components/modules/content/Zeitstrahl'
import FontSize from '@config/theme/definitions/fontSize'
import Image from '@components/media/image'
import IconButton from '@components/core/IconButton'

type ZeitstrahlSlideProps = {
  slide: SlideProps
  handleVideoClickToOpen: () => void
}

export default function ZeitstrahlSlide({
  slide,
  handleVideoClickToOpen,
}: ZeitstrahlSlideProps) {
  const hasImage = !!slide.image
  const hasVideo = !!slide.videoDesktop || !!slide.videoYouTube

  return (
    <>
      <Box
        sx={(theme) => ({
          backgroundColor: theme.palette.background.main,
          color: theme.palette.background.primary,
          borderRadius: '16px',
          contain: 'paint',
          boxShadow: '0px 12px 88px -20px rgba(0, 0, 0, 0.35)',
          height: '560px',

          [theme.breakpoints.up('lg')]: {
            minHeight: '506px',
            height: '1px',
          },
        })}
      >
        <Grid container spacing={0} sx={{ height: '100%' }}>
          <Grid
            item
            xs={12}
            md={hasImage ? 8 : 12}
            sx={(theme) => ({
              [theme.breakpoints.down('md')]: {
                height: hasImage ? 'calc(100% - 160px)' : 'auto',
              },
            })}
          >
            <Box
              sx={(theme) => ({
                padding: theme.spacing(6),
                display: 'flex',
                flexDirection: 'column',
                height: '100%',

                [theme.breakpoints.up('lg')]: {
                  padding: theme.spacing(9, 12),
                },
              })}
            >
              {slide.tags && slide.tags.length > 0 && (
                <Box
                  sx={(theme) => ({
                    display: 'flex',
                    gap: theme.spacing(2),
                    flexWrap: 'wrap',
                  })}
                >
                  {slide.tags.map((tag, index) => (
                    <Box
                      key={tag}
                      sx={(theme) => ({
                        fontSize: FontSize.get('xs'),
                        fontWeight: 700,
                        lineHeight: 1,
                        color: theme.palette.text.primary,
                        backgroundColor: theme.palette.common.yellow[500],
                        borderRadius: theme.spacing(2),
                        padding: theme.spacing(2, 4),
                        [theme.breakpoints.down('sm')]: {
                          display: index < 2 ? 'block' : 'none',
                        },
                      })}
                    >
                      {tag}
                    </Box>
                  ))}
                </Box>
              )}
              <Typography variant="h4" sx={{ marginTop: 'auto' }}>
                {slide.headline}
              </Typography>
              <Box
                sx={(theme) =>
                  hasImage
                    ? {
                        [theme.breakpoints.down('sm')]: {
                          position: 'relative',
                          mt: theme.spacing(2),
                          mb: theme.spacing(3),

                          '> p': {
                            maxHeight: '120px',
                            overflow: 'scroll',
                            m: 0,
                            pb: theme.spacing(5),
                            pr: theme.spacing(2),
                          },

                          ':after': {
                            content: '""',
                            position: 'absolute',
                            right: 0,
                            bottom: 0,
                            left: 0,
                            height: theme.spacing(10),
                            backgroundImage:
                              'linear-gradient(180deg, rgba(255, 255, 255, 0), #ffffff)',
                            transform: 'translateZ(0)',
                          },
                        },
                      }
                    : {}
                }
              >
                {renderRichText(slide.copy)}
              </Box>
              {!!slide.links && (
                <Box
                  sx={{
                    ' > p': {
                      margin: 0,
                      display: 'inline',
                      a: {
                        display: 'inline-flex',
                        alignItems: 'center',
                        gap: 1,
                        mr: 3,
                        whiteSpace: 'nowrap',
                        fontSize: FontSize.get('lg'),
                        fontWeight: 700,

                        svg: {
                          height: '16px',
                          width: '16px',
                        },
                      },
                    },
                  }}
                >
                  {renderRichText(slide.links)}
                </Box>
              )}
            </Box>
          </Grid>
          {hasImage && !!slide.image && (
            <Grid
              item
              xs={12}
              md={4}
              sx={(theme) => ({
                position: 'relative',
                height: '160px',

                [theme.breakpoints.up('md')]: {
                  height: '100%',
                },
              })}
            >
              <Image
                eager
                objectFit="cover"
                objectPosition="center"
                image={slide.image.imageDesktop}
                imageMobile={slide.image.imageMobile}
                sx={{
                  display: 'flex',
                  img: {
                    objectFit: 'cover',
                    objectPosition: '50% 50%',
                    transform: 'translateZ(0)',
                  },
                }}
              />
              {hasVideo && (
                <IconButton
                  name="Play"
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform:
                      'translateX(-50%) translateY(-50%) translateZ(0)',
                  }}
                  onClick={() => handleVideoClickToOpen()}
                />
              )}
            </Grid>
          )}
        </Grid>
      </Box>
    </>
  )
}
