import React, { useEffect, useRef, useState } from 'react'
import { Box } from '@mui/system'

import { SPEED, TIMING_FUNCTION } from '../index'

type ZeitstrahlBackgroundProps = {
  activeIndex: number
  length: number
  backgroundImage: BASE.Contentful.IAssetImage
  loaded: boolean
}

export default function ZeitstrahlBackground({
  activeIndex,
  length,
  backgroundImage,
  loaded,
}: ZeitstrahlBackgroundProps) {
  const [xPos, setXPos] = useState(0)
  const backgroundRef = useRef<HTMLImageElement>(null)

  useEffect(() => {
    function handleResize() {
      const windowWidth = window.innerWidth
      const imageWidth =
        backgroundRef.current?.getBoundingClientRect().width || 0

      const pos = ((imageWidth - windowWidth) / (length - 1)) * activeIndex * -1
      setXPos(pos)
    }

    handleResize()
    window.addEventListener('resize', handleResize)

    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [activeIndex])

  return (
    <Box
      ref={backgroundRef}
      component="img"
      sx={[
        {
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          bottom: 0,
          transform: `translateX(${xPos}px)`,
        },

        loaded && {
          transition: `transform ${SPEED * 0.8}ms ${TIMING_FUNCTION}`,
        },
      ]}
      src={backgroundImage.localFile?.publicURL}
      title={backgroundImage.title || ''}
      alt={backgroundImage.description || ''}
      loading="eager"
    />
  )
}
