import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { useMediaQuery, useTheme, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { SPEED, TIMING_FUNCTION } from '../index'

type ZeitstrahlIndicatorProps = {
  activeIndex: number
  length: number
  slideTo: (index: number) => void
  loaded: boolean
}

export default function ZeitstrahlIndicator({
  activeIndex,
  length,
  slideTo,
  loaded,
}: ZeitstrahlIndicatorProps) {
  const intl = useIntl()
  const theme = useTheme()
  const isMedium = useMediaQuery(theme.breakpoints.up('md'))
  const [paginationClasses, setPaginationClasses] = useState<string[]>([])

  useEffect(() => {
    const paginationElements = document.querySelectorAll(
      '.swiper-pagination .swiper-pagination-bullet'
    )

    const classNames: string[] = []
    paginationElements.forEach((element) => {
      classNames.push([element.classList].join(' ').replaceAll('swiper-', ''))
    })
    setPaginationClasses(classNames)
  }, [activeIndex])

  return isMedium ? (
    <Box
      sx={(theme) => ({
        display: 'flex',
        gap: theme.spacing(4),
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.background.main,
        padding: theme.spacing(4, 6),
        borderRadius: theme.spacing(6),
        width: '290px',
      })}
    >
      {Array.from({ length }, (_, index) => (
        <Box
          component="button"
          key={index}
          sx={[
            {
              height: theme.spacing(4),
              borderRadius: theme.spacing(2),
              width:
                index === activeIndex ? theme.spacing(20) : theme.spacing(4),
              backgroundColor:
                index <= activeIndex
                  ? theme.palette.common.red[500]
                  : theme.palette.grey[300],
              transform: 'scale(1)',

              '&:focus-visible': {
                outline: 'auto',
              },

              '&:hover': {
                backgroundColor: theme.palette.common.red[500],
              },

              display: 'none',

              '&.pagination-bullet-active-main': {
                display: 'block',
              },

              '&.pagination-bullet-active-prev, &.pagination-bullet-active-next':
                {
                  display: 'block',
                  transform: length > 5 ? 'scale(0.7)' : 'scale(1)',
                },
            },
            loaded && {
              transition: `background-color ${
                SPEED * 0.6
              }ms ${TIMING_FUNCTION}, transform ${
                SPEED * 0.6
              }ms ${TIMING_FUNCTION}, width ${
                SPEED * 0.6
              }ms ${TIMING_FUNCTION}`,
            },
          ]}
          className={paginationClasses[index]}
          onClick={() => slideTo(index)}
        ></Box>
      ))}
    </Box>
  ) : (
    <Box
      sx={(theme) => ({
        backgroundColor: theme.palette.background.main,
        padding: theme.spacing(1, 6),
        borderRadius: theme.spacing(6),
      })}
    >
      <Typography
        component="span"
        sx={(theme) => ({
          color: theme.palette.common.red[500],
          fontSize: theme.fontSize.get('sm'),
        })}
        fontWeight={700}
      >
        {activeIndex + 1}{' '}
        {intl.formatMessage({ id: 'zeitstrahl.indicator.mobile' })} {length}
      </Typography>
    </Box>
  )
}
