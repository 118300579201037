import React, { useEffect, useState } from 'react'
import type { Swiper as SwiperTypes } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'

import { Theme } from '@mui/material'
import theme from '@config/theme'

import { ModuleUnion } from '@system/utils/PageModuleParser'
import { Box } from '@mui/system'

import PopUpVideo from '@components/media/popUpVideo'
import { ContentfulYouTubeProps } from '@components/modules/content/ContentfulYouTube'
import { ContentfulImageProps } from '@components/modules/content/ContentfulImage'
import IconButton from '@components/core/IconButton'

import ZeitstrahlIndicator from './components/indicator'
import ZeitstrahlSlide from './components/slide'
import ZeitstrahlBackground from './components/background'

export type ZeitstrahlSlideProps = {
  tags: string[]
  headline: string
  copy: BASE.Contentful.IRichtext<ModuleUnion>
  links?: BASE.Contentful.IRichtext<ModuleUnion>
  videoYouTube?: ContentfulYouTubeProps
  image?: ContentfulImageProps
  videoDesktop?: BASE.Contentful.IAsset
  videoMobile?: BASE.Contentful.IAsset
}

export type ZeitstrahlProps = BASE.Helpers.ModuleDefaultProps & {
  backgroundImage: BASE.Contentful.IAssetImage
  initialSlide?: number
  slides: ZeitstrahlSlideProps[]
}

export const SPEED = 1200
export const TIMING_FUNCTION = 'cubic-bezier(0.19, 1, 0.22, 1)'
const SESSION_STORAGE_SLIDE_INDEX = 'zeitstrahlIndex'

const styles = (theme: Theme) => ({
  iconButtonWrapper: {
    backgroundColor: theme.palette.background.main,
    p: { xs: theme.spacing(1.5), md: theme.spacing(3) },
    borderRadius: '50%',
    'button > div': {
      padding: 0,
      minWidth: { xs: '36px', md: '56px' },
      minHeight: { xs: '36px', md: '56px' },
    },
    'button > div > svg': {
      width: { xs: '0.8em', md: '1em' },
      height: { xs: '0.8em', md: '1em' },
    },
  },
})

export default function Zeitstrahl({
  backgroundImage,
  initialSlide,
  slides,
}: ZeitstrahlProps) {
  const [activeIndex, setActiveIndex] = useState(0)
  const [loaded, setLoaded] = useState(false)
  const [showVideo, setShowVideo] = useState(false)
  const [swiper, setSwiper] = useState<SwiperTypes | null>(null)

  const pos = (index: number) => {
    if (index === activeIndex) {
      return 50
    } else if (index < activeIndex) {
      return 100
    }
    return 0
  }

  function onSlideChange(swiper: SwiperTypes) {
    setActiveIndex(swiper.activeIndex)
    sessionStorage.setItem(
      SESSION_STORAGE_SLIDE_INDEX,
      String(swiper.activeIndex)
    )
  }

  function slideTo(index: number) {
    swiper?.slideTo(index)
  }

  useEffect(() => {
    if (!!swiper) {
      const index = sessionStorage.getItem(SESSION_STORAGE_SLIDE_INDEX)
      // If there is an index in the session storage, slide to it
      if (index) {
        swiper?.slideTo(Number(index), 0)
        sessionStorage.removeItem(SESSION_STORAGE_SLIDE_INDEX)
      }

      // If there is an initial slide, slide to it
      if (!index && !!initialSlide) {
        swiper?.slideTo(initialSlide, 0)
      }

      setTimeout(() => setLoaded(true), 100)
    }
  }, [swiper])

  return (
    <Box
      component="section"
      role="region"
      sx={{
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <ZeitstrahlBackground
        backgroundImage={backgroundImage}
        activeIndex={activeIndex}
        length={slides.length}
        loaded={loaded}
      />

      <Box
        sx={(theme) => ({
          position: 'relative',
          margin: { xs: theme.spacing(7, 0), lg: theme.spacing(30, 0, 20, 0) },

          '@media (min-width: 1024px)': {
            '@media (max-height: 1000px)': {
              margin: theme.spacing(12, 0),
            },
          },

          '.swiper': {
            overflow: 'visible',
            padding: theme.spacing(0, 4),
          },

          '.swiper-wrapper': {
            transitionTimingFunction: TIMING_FUNCTION,
          },

          '.swiper-pagination': {
            display: 'none',
          },

          '.swiper-slide': {
            height: 'auto',

            '> div': {
              height: '100%',
            },
          },

          '.swiper-slide-active': {
            position: 'relative',
            zIndex: 1,
          },
        })}
      >
        <Box>
          <Swiper
            onSwiper={setSwiper}
            onSlideChange={onSlideChange}
            slidesPerView={1.1}
            spaceBetween={16}
            speed={SPEED}
            preventClicks={false}
            preventClicksPropagation={false}
            pagination={{
              dynamicBullets: true,
              dynamicMainBullets: 4,
            }}
            modules={[Pagination]}
            breakpoints={{
              [theme.breakpoints.values.md]: {
                centeredSlides: true,
                spaceBetween: 50,
                slidesPerView: 1.2,
                allowTouchMove: false,
              },
            }}
          >
            {slides.map((slide, index) => (
              <SwiperSlide key={index}>
                <Box
                  sx={(theme) => ({
                    [theme.breakpoints.up('lg')]: {
                      position: 'relative',
                      left: `${pos(index)}%`,
                      width: !!slide.image ? '80%' : '70%',
                      height: '100%',
                      maxWidth: !!slide.image ? '1098px' : '704px',
                      transform: `translateX(-${pos(index)}%)`,
                      transition: `left ${
                        SPEED * (loaded ? 1 : 0)
                      }ms ${TIMING_FUNCTION}, transform ${
                        SPEED * (loaded ? 1 : 0)
                      }ms ${TIMING_FUNCTION}`,
                      cursor: activeIndex !== index ? 'pointer' : 'default',

                      a: {
                        pointerEvents: activeIndex === index ? 'auto' : 'none',
                      },

                      '&:focus-visible': {
                        outline: 'auto',
                      },
                    },

                    [theme.breakpoints.up('xl')]: {
                      width: !!slide.image ? '70%' : '50%',
                    },
                  })}
                  onClick={() => swiper?.slideTo(index)}
                  tabIndex={0}
                >
                  <ZeitstrahlSlide
                    slide={slide}
                    handleVideoClickToOpen={() => setShowVideo(true)}
                  />
                </Box>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>

        <Box
          sx={(theme) => ({
            position: 'relative',
            zIndex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            gap: theme.spacing(4),
            filter: 'drop-shadow(0px 6px 16px rgba(0, 0, 0, 0.35))',
            mt: { xs: 4, md: 12, lg: 22 },

            '@media (min-width: 1024px)': {
              '@media (max-height: 1000px)': {
                mt: 6,
              },
            },
          })}
        >
          <Box sx={(theme) => styles(theme as Theme).iconButtonWrapper}>
            <IconButton
              name="ArrowUp"
              sx={{ rotate: '-90deg' }}
              onClick={() => swiper?.slidePrev()}
              disabled={activeIndex === 0}
              tabIndex={0}
            />
          </Box>
          <ZeitstrahlIndicator
            activeIndex={activeIndex}
            length={slides.length}
            slideTo={slideTo}
            loaded={loaded}
          />
          <Box sx={(theme) => styles(theme as Theme).iconButtonWrapper}>
            <IconButton
              name="ArrowDown"
              sx={{ rotate: '-90deg' }}
              onClick={() => swiper?.slideNext()}
              disabled={slides.length === activeIndex + 1}
              tabIndex={0}
            />
          </Box>
        </Box>
      </Box>

      {showVideo && !!slides[activeIndex].videoDesktop && (
        <PopUpVideo
          media={slides[activeIndex].videoDesktop as BASE.Contentful.IAsset}
          mediaMobile={
            (slides[activeIndex].videoMobile as BASE.Contentful.IAsset) || null
          }
          triggerVideoPlay={showVideo}
          handleVideoClickToClose={() => setShowVideo(false)}
        />
      )}

      {showVideo &&
        !!slides[activeIndex].videoYouTube &&
        !slides[activeIndex].videoDesktop && (
          <PopUpVideo
            mediaYouTube={
              (slides[activeIndex].videoYouTube as ContentfulYouTubeProps) ||
              null
            }
            triggerVideoPlay={showVideo}
            handleVideoClickToClose={() => setShowVideo(false)}
          />
        )}
    </Box>
  )
}
